<template>
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert">

              </custom-alert>
              <div class="card">
                <div class="card-body  table-responsive table-head">
                  <div class="row bor-bot pb-3">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2">Consolidate</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item"><Breadcrumbs/></li>
                        </ol>
                      </div>
                    </div>


                  </div>

                    <div class="table-responsive">
                      <b-overlay :show="showLoader" no-wrap></b-overlay>
                      <table class="table table-sm table-bordered">
                        <thead>
                        <tr>
                          <th style="width: 350px" v-if="subdomain == 'shooting'">
                            <span class="text-danger">Note: Individual membership</span><br>
                            <span class="text-danger">consolidation can be done.</span><br>
                            <span class="text-danger">Family and Club member</span><br>
                            <span class="text-danger">consolidation are under</span><br>
                            <span class="text-danger"> development.</span>
                          </th>
                          <th style="width: 350px" v-if="subdomain == 'waterski'">
                            <span class="text-danger">Note: Individual membership</span><br>
                            <span class="text-danger">consolidation can be done.</span><br>
                            <span class="text-danger">Family consolidation are</span><br>
                            <span class="text-danger">under development.</span><br>
                          </th>
                          <th style="width: 396px"><div class="form-group">
                            <strong>Delete</strong>
                            <select2ajax v-model="form.member_id1" id="member_id1" v-on:input="member1change($event)">
                            </select2ajax>
<!--
                           <Select2 v-model="form.member_id1" @change="member1change" :options="memberOption" :settings="{ placeholder: 'Click to select one', width: '100%' }" />
-->
                           <span class="text-danger" v-if="memberErrorMsg != null">{{ memberErrorMsg }}</span><span class="text-danger">{{ errors.member_id1}}</span>
                          </div></th>
                          <th style="width: 8px !important;"></th>
                          <th style="width: 396px">
                            <div class="form-group">
                              <strong>Keep</strong>
                              <select2ajax v-model="form.member_id2" id="member_id2" v-on:input="member2change($event)">
                              </select2ajax>
                           <span  class="text-danger" v-if="memberErrorMsg != null">{{ memberErrorMsg }}</span><span class="text-danger">{{ errors.member_id2}}</span>
                       </div></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <td>Member id</td>
                        <td><input type="text" readonly class="form-control" name="mergeDelete.member_id" v-model="memberPersonal1Details.member_id"></td>
                          <td></td>
                        <td><input type="text" readonly class="form-control" name="mergeDelete.member_id" v-model="memberPersonal2Details.member_id"></td>
                        </tr>
                        <tr v-if="subdomain == 'shooting'">
                          <td>Title <sup class="text-danger" v-if="fields.personal.title.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.title" v-model="memberPersonal1Details.title"></td>
                          <td><a :key="title_key_true" href="#" @click.prevent="dataCopy('title')" v-if="copy.title"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="title_key_false" v-else href="#"  @click.prevent="oldData('title')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.title1" v-model="memberPersonal2Details.title"><span class="text-sm text-danger">{{ errors.title}}</span></td>
                        </tr>
                        <tr>
                          <td>First Name <sup class="text-danger" v-if="fields.personal.firstname.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.firstName" v-model="memberPersonal1Details.first_name"></td>
                          <td><a href="#" @click.prevent="dataCopy('first_name')" v-if="copy.first_name"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('first_name')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.firstName1" v-model="memberPersonal2Details.first_name"><span class="text-sm text-danger">{{ errors.firstName}}</span></td>
                        </tr>
                        <tr>
                          <td>Middle Initial <sup class="text-danger" v-if="fields.personal.middlename.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.middle_name" v-model="memberPersonal1Details.middle_name"></td>
                          <td><a href="#" @click.prevent="dataCopy('middle_name')" v-if="copy.middle_name"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('middle_name')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.middle_name1" v-model="memberPersonal2Details.middle_name"></td>
                        </tr>
                        <tr>
                          <td>last Name <sup class="text-danger" v-if="fields.personal.lastname.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.lastname" v-model="memberPersonal1Details.last_name" ></td>
                          <td><a href="#" @click.prevent="dataCopy('last_name')" v-if="copy.last_name"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('last_name')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.lastname1" v-model="memberPersonal2Details.last_name"><span class="text-sm text-danger">{{ errors.lastName}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'waterski'">
                          <td>Suffix <sup class="text-danger" v-if="fields.personal.suffix.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.suffix" v-model="memberPersonal1Details.suffix" ></td>
                          <td><a href="#" @click.prevent="dataCopy('suffix')" v-if="copy.suffix"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('suffix')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.suffix1" v-model="memberPersonal2Details.suffix"></td>
                        </tr>
                        <tr>
                          <td>Club/Team</td>
                          <td><Select2 :disabled="1" v-model="form.teamRoster1" :options="clubOptions" :settings="{ multiple: true, placeholder: 'Click to select one or multiple', width: '100%' }"
                                       /></td>
                          <td><a href="#" @click.prevent="dataCopy('teamRoster')" v-if="copy.teamRoster"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('teamRoster')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><Select2 v-model="form.teamRoster2"  :options="clubOptions" :settings="{ multiple: true, placeholder: 'Click to select one or multiple', width: '100%' }"
                                       /><span class="text-sm text-danger">{{ errors.teamRoster}}</span></td>
                        </tr>
                        <tr>
                          <td>Gender <sup class="text-danger" v-if="fields.personal.gender.required">*</sup></td>
                          <td><template v-for="(choice, key) in fields.personal.gender.choices">
                                                                <input type="radio" :disabled="1" :id="choice.value" v-model="memberPersonal1Details.gender" name="mergeDelete.gender" :value="choice.label" :key="'v-radio_' + key">
                                                                <label class="gender-label-span" :for="choice.value" :key="'v-gender_label_'+key">{{ choice.label }}</label>
                                                                <br :key="'v-gender_br_'+key">
                                                            </template></td>
                          <td><a href="#" @click.prevent="dataCopy('gender')" v-if="copy.gender"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('gender')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><template v-for="(choice, key) in fields.personal.gender.choices">
                            <input type="radio" :id="choice.value" v-model="memberPersonal2Details.gender" name="mergeDelete1.gender" :value="choice.label" :key="'v-radio_' + key">
                            <label class="gender-label-span" :for="choice.value" :key="'v-gender_label_'+key">{{ choice.label }}</label>
                            <br :key="'v-gender_br_'+key">
                          </template><span class="text-sm text-danger">{{ errors.gender}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'waterski'">
                          <td>Federation <sup class="text-danger" v-if="fields.general.sport_discipline.home_federation.required">*</sup></td>
                          <td><select :disabled="1" class="form-control" id="home_federation" name="memberPersonal1Details.home_federation"
                                      v-model="memberPersonal1Details.home_federation">
                            <option value="">Select</option>
                            <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                          </select>
                          <div v-if="memberPersonal1Details.home_federation !='USA' && memberPersonal1Details.home_federation !=''" style='margin-top:5px;'>
                                    <input :disabled="1" type="text" id="skier_license" class="form-control" maxlength="9"
                                        placeholder="Home Federation EMS Athlete Number" name="mergeDelete.skier_license"
                                        v-model="memberPersonal1Details.skier_license">
                            </div>
                        </td>
                          <td><a href="#" @click.prevent="dataCopy('home_federation')" v-if="copy.home_federation"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('home_federation')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><select class="form-control form-control2" id="home_federation1" name="memberPersonal1Details.home_federation1"
                                      v-model="memberPersonal2Details.home_federation">
                            <option value="">Select</option>
                            <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                          </select><span class="text-sm text-danger">{{ errors.federation}}</span>
                          <div v-if="memberPersonal2Details.home_federation !='USA' && memberPersonal2Details.home_federation !=''" style='margin-top:5px;'>
                                <div class="form-group">
                                    <input type="text" id="skier_license1" class="form-control" maxlength="9"
                                        placeholder="Home Federation EMS Athlete Number" name="mergeDelete.skier_license"
                                        v-model="memberPersonal2Details.skier_license">
                                    <span class="font-13 text-danger">{{ errors.skier_license }}</span>
                                </div>
                            </div>
                        </td>
                        </tr>

                        <tr v-if="subdomain == 'waterski'">
                          <td>1st Choice <sup class="text-danger" v-if="fields.general.sport_discipline.first_choice.required">*</sup></td>
                          <td><Select2  :disabled="1" v-if="fields.general.sport_discipline.first_choice.multiple" v-model="memberPersonal1Details.first_choice" name="verifyData.first_choice" id="verifyData-disciplines" :options="disciplines_list" :settings="{ multiple: true, placeholder: 'Click to select one or multiple', width: '100%' }" />
                            <select class="form-control" :disabled="1" id="verifyData-first-choice" name="verifyData.first_choice"
                                    v-model="memberPersonal1Details.first_choice" v-else>
                              <option value="">Select</option>
                              <option v-for="(discipline, index) in disciplines" :value="index"
                                      :key="'types'+index">{{ discipline }}
                              </option>
                            </select></td>
                          <td><a href="#" @click.prevent="dataCopy('first_choice')" v-if="copy.first_choice"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('first_choice')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><Select2 v-if="fields.general.sport_discipline.first_choice.multiple" v-model="memberPersonal1Details.first_choice" name="verifyData.first_choice1" id="verifyData-disciplines" :options="disciplines_list" :settings="{ multiple: true, placeholder: 'Click to select one or multiple', width: '100%' }" />
                            <select class="form-control form-control2" id="verifyData-first-choice1" name="verifyData.first_choice"
                                    v-model="memberPersonal2Details.first_choice" v-else>
                              <option value="">Select</option>
                              <option v-for="(discipline, index) in disciplines" :value="index"
                                      :key="'types'+index">{{ discipline }}
                              </option>
                            </select><span class="text-sm text-danger">{{ errors.firstChoice}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'waterski'">
                          <td>2nd Choice <sup class="text-danger" v-if="fields.general.sport_discipline.second_choice.required">*</sup></td>
                          <td> <select :disabled="1" class="form-control" id="secondchoice" name="familyForm.second_choice" v-model="memberPersonal1Details.second_choice" @change="collectSecondChoice">
                            <option value="">Select</option>
                            <option v-for="(discipline, index) in disciplines" :value="index"
                                    :key="'types2-'+index">{{ discipline }}
                            </option>
                          </select></td>
                          <td><a href="#" @click.prevent="dataCopy('second_choice')" v-if="copy.second_choice"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('second_choice')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><select class="form-control form-control2" id="secondchoice1" name="familyForm.discipline_2" v-model="memberPersonal2Details.second_choice" @change="collectSecondChoice">
                            <option value="">Select</option>
                            <option v-for="(discipline, index) in disciplines" :value="index"
                                    :key="'types2-'+index">{{ discipline }}
                            </option>
                          </select><span class="text-sm text-danger">{{ errors.secondChoice}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'waterski'">
                          <td>Veteran Status <sup class="text-danger">*</sup></td> 
                          <td><div class="form-group">
                              <label for="veteran"><input type="radio" :disabled="1" id="veteran" name="verifyPersonal.veteran_status" value="Veteran" v-model="memberPersonal1Details.veteran_status"> Veteran</label><br>
                              <label for="non-Veteran">  <input type="radio" :disabled="1" id="non-Veteran" name="verifyPersonal.veteran_status" value="Non-Veteran" v-model="memberPersonal1Details.veteran_status"> Non-Veteran</label><br>
                              <label for="Active-or-Reserve"> <input type="radio" :disabled="1" id="Active-or-Reserve" name="verifyPersonal.veteran_status" value="Active or Reserve" v-model="memberPersonal1Details.veteran_status"> Active or Reserve</label><br>
                              <label for="vet-prefer-not-to-say"> <input type="radio" :disabled="1" id="vet-prefer-not-to-say" name="verifyPersonal.veteran_status" value="Prefer not to say" v-model="memberPersonal1Details.veteran_status"> Prefer not to say</label><br>
                          </div></td>
                          <td><a href="#" @click.prevent="dataCopy('veteran_status')" v-if="copy.veteran_status"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('veteran_status')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                            <div class="form-group">
                            <label for="veteran"><input type="radio" id="veteran1" name="verifyPersonal.veteran_status1" value="Veteran" v-model="memberPersonal2Details.veteran_status"> Veteran</label><br>
                            <label for="non-Veteran">  <input type="radio" id="non-Veteran1" name="verifyPersonal.veteran_status1" value="Non-Veteran" v-model="memberPersonal2Details.veteran_status"> Non-Veteran</label><br>
                            <label for="Active-or-Reserve"> <input type="radio" id="Active-or-Reserve1" name="verifyPersonal.veteran_status1" value="Active or Reserve" v-model="memberPersonal2Details.veteran_status"> Active or Reserve</label><br>
                            <label for="vet-prefer-not-to-say"> <input type="radio" id="vet-prefer-not-to-say1" name="verifyPersonal.veteran_status1" value="Prefer not to say" v-model="memberPersonal2Details.veteran_status"> Prefer not to say</label><br>
                          </div><span class="text-sm text-danger">{{ errors.veteranStatus}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'shooting'">
                          <td>Veteran Status <sup class="text-danger">*</sup></td> 
                          <td><div class="form-group">
                              <label for="Yes"><input type="radio" :disabled="1" id="yes" name="verifyPersonal.veteran_status" value="Yes" v-model="memberPersonal1Details.veteran_status"> Yes</label><br>
                              <label for="No">  <input type="radio" :disabled="1" id="no" name="verifyPersonal.veteran_status" value="No" v-model="memberPersonal1Details.veteran_status"> No</label><br>
                          </div></td>
                          <td><a href="#" @click.prevent="dataCopy('veteran_status')" v-if="copy.veteran_status"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('veteran_status')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                            <div class="form-group">
                            <label for="Yes"><input type="radio" id="yes1" name="verifyPersonal.veteran_status1" value="Yes" v-model="memberPersonal2Details.veteran_status"> Yes</label><br>
                            <label for="No">  <input type="radio" id="no1" name="verifyPersonal.veteran_status1" value="No" v-model="memberPersonal2Details.veteran_status"> No</label><br>
                          </div><span class="text-sm text-danger">{{ errors.veteranStatus}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'waterski'">
                          <td>Race <sup class="text-danger" v-if="fields.demographic.race.required">*</sup></td>
                          <td><div v-for="diversity in diversities" :key="diversity.name">
                            <label :for="'diversity_id'+diversity.id">
                              <input type="radio"  name="familyForm.diversity_id1" :value="diversity.id"
                                     :id="'diversity_id'+diversity.id"
                                     v-model="memberPersonal1Details.diversity_id" :disabled="1">
                              {{ diversity.name }}</label> <br>
                          </div></td>
                          <td><a href="#" @click.prevent="dataCopy('diversity_id')" v-if="copy.diversity_id"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('diversity_id')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><div v-for="diversity in diversities" :key="diversity.name">
                            <label :for="'diversity_id'+diversity.id">
                              <input type="radio" name="familyForm.diversity_id2" :value="diversity.id"
                                     :id="'diversity_id1'+diversity.id"
                                     v-model="memberPersonal2Details.diversity_id">
                              {{ diversity.name }}</label> <br>
                          </div><span class="text-sm text-danger">{{ errors.race}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'waterski'">
                          <td>Disability Status <sup class="text-danger" v-if="fields.demographic.disability_status.required">*</sup></td>
                          <td><div class="form-group">
                            <label for="normal"> <input type="radio" :disabled="1" id="normal" name="verifyPersonal.disability" value="Persons without Disability" v-model="memberPersonal1Details.disability"> Persons without Disability</label><br>

                            <label for="Persons-with-Disability"><input type="radio" :disabled="1" id="Persons-with-Disability" name="verifyPersonal.disability" value="Persons with Disability" v-model="memberPersonal1Details.disability"> Persons with Disability</label><br>

                            <label for="disability-Prefer-not-to-say"><input type="radio" :disabled="1" id="disability-Prefer-not-to-say" name="verifyPersonal.disability" value="Prefer not to say" v-model="memberPersonal1Details.disability"> Prefer not to say</label><br>
                          </div></td>
                          <td><a href="#" @click.prevent="dataCopy('disability')" v-if="copy.disability"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('disability')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><div class="form-group">
                            <label for="normal"> <input type="radio" id="normal1" name="verifyPersonal.disability1" value="Persons without Disability" v-model="memberPersonal2Details.disability"> Persons without Disability</label><br>

                            <label for="Persons-with-Disability"><input type="radio" id="Persons-with-Disability1" name="verifyPersonal.disability1" value="Persons with Disability" v-model="memberPersonal2Details.disability"> Persons with Disability</label><br>

                            <label for="disability-Prefer-not-to-say"><input type="radio" id="disability-Prefer-not-to-say1" name="verifyPersonal.disability1" value="Prefer not to say" v-model="memberPersonal2Details.disability"> Prefer not to say</label><br>
                          </div><span class="text-sm text-danger">{{ errors.disability}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'shooting'">
                          <td>Ethnicity <sup class="text-danger">*</sup></td>
                          <td>
                            <div class="form-group radiotext">
                            <div v-for="(diversity,i) in diversities" :key="i">
                                <input type="radio" :disabled="1" :id="'diversity'+diversity.name" name="diversity_id" :value="diversity.id" v-model="memberPersonal1Details.diversity_id">
                                <label  :for="'diversity'+diversity.name">{{diversity.name}}</label> <br>
                            </div>
                        </div>
                          </td> 
                          <td><a href="#" @click.prevent="dataCopy('diversity_id')" v-if="copy.diversity_id"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('diversity_id')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                            <div class="form-group radiotext">
                            <div v-for="(diversity,i) in diversities" :key="i">
                                <input type="radio" :id="'diversity'+diversity.name" name="diversity_id1" :value="diversity.id" v-model="memberPersonal2Details.diversity_id">
                                <label  :for="'diversity'+diversity.name">{{diversity.name}}</label> <br>
                            </div>
                            <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                        </div>
                          </td> 
                        </tr>
                        <tr v-if="subdomain == 'waterski'">
                          <td>Ethnicity <sup class="text-danger">*</sup></td> 
                          <td><div class="form-group">
                            <label for="hispanic-or-latino"><input type="radio" :disabled="1" id="hispanic-or-latino" name="ethnicity" value="Hispanic or Latino" v-model="memberPersonal1Details.ethnicity"> Hispanic or Latino</label><br>

                            <label for="non-hispanic-or-latino"> <input type="radio" :disabled="1" id="non-hispanic-or-latino" name="ethnicity" value="Non-Hispanic or Latino" v-model="memberPersonal1Details.ethnicity"> Non-Hispanic or Latino</label><br>

                            <label for="ethnicity-Prefer-not-to-say"> <input type="radio" :disabled="1" id="ethnicity-Prefer-not-to-say" name="ethnicity" value="Prefer not to say" v-model="memberPersonal1Details.ethnicity"> Prefer not to say</label><br>
                          </div></td>
                          <td><a href="#" @click.prevent="dataCopy('ethnicity')" v-if="copy.ethnicity"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('ethnicity')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><div class="form-group">
                            <label for="hispanic-or-latino"><input type="radio" id="hispanic-or-latino1" name="ethnicity1" value="Hispanic or Latino" v-model="memberPersonal2Details.ethnicity"> Hispanic or Latino</label><br>

                            <label for="non-hispanic-or-latino"> <input type="radio" id="non-hispanic-or-latino1" name="ethnicity1" value="Non-Hispanic or Latino" v-model="memberPersonal2Details.ethnicity"> Non-Hispanic or Latino</label><br>

                            <label for="ethnicity-Prefer-not-to-say"> <input type="radio" id="ethnicity-Prefer-not-to-say1" name="ethnicity1" value="Prefer not to say" v-model="memberPersonal2Details.ethnicity"> Prefer not to say</label><br>
                          </div><span class="text-sm text-danger">{{ errors.ethnicity}}</span></td>
                        </tr>
                        
                        <tr v-if="subdomain == 'shooting'">
                          <td>Disciplines of Interest <sup class="text-danger" v-if="fields.general.sport_discipline.first_choice.required">*</sup></td>
                          <td>
                            <Select2 :disabled="1" v-model="form.memberDiscipline1" name="form.disciplines1" id="disciplines" :options="disciplines_list" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                            </td>
                          <td><a :key="discipline_interest_key_true" href="#" @click.prevent="dataCopy('discipline_interest')" v-if="copy.discipline_interest"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="discipline_interest_key_false" v-else href="#"  @click.prevent="oldData('discipline_interest')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                           
                              <Select2 v-model="form.memberDiscipline2" name="form.disciplines2" id="disciplines1" :options="disciplines_list" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                              <span class="text-sm text-danger">{{ errors.discipline_interest}}</span>
                          </td>
                        </tr>
                        
                        <tr v-if="subdomain == 'shooting'">
                          <td>Primary Role of Interest <sup class="text-danger" v-if="fields.demographic.primary_area_interest.required">*</sup></td>
                          <td>
                            <select class="form-control" id="primary_area_interest" :disabled="1" v-model="memberPersonal1Details.primary_area_interest"
                                    name="mergeDelete.primary_area_interest">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in fields.demographic.primary_area_interest.options" :key="'pai'+k" :value="item">{{ item }}</option>
                            </select>
                            </td>
                          <td><a :key="primary_interest_key_true" href="#" @click.prevent="dataCopy('primary_area_interest')" v-if="copy.primary_area_interest"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="primary_interest_key_false" v-else href="#"  @click.prevent="oldData('primary_area_interest')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                           <select class="form-control form-control2" id="primary_area_interest1"  v-model="memberPersonal2Details.primary_area_interest"
                                    name="mergeDelete.primary_area_interest1">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in fields.demographic.primary_area_interest.options" :key="'pai'+k" :value="item">{{ item }}</option>
                            </select><span class="text-sm text-danger">{{ errors.primary_area_interest}}</span>
                          </td>
                        </tr>

                        <tr v-if="subdomain == 'shooting'">
                          <td>Category <sup class="text-danger" v-if="fields.demographic.category.required">*</sup></td>
                          <td>
                            <select class="form-control" id="category1" :disabled="1" v-model="memberPersonal1Details.category"
                                    name="mergeDelete.category">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in categories" :key="'pai'+k" :value="item.id">{{ item.name }}</option>
                            </select>
                            </td>
                          <td><a :key="category_key_true" href="#" @click.prevent="dataCopy('category')" v-if="copy.category"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="category_key_false" v-else href="#"  @click.prevent="oldData('category')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                           <select class="form-control form-control2" id="category"  v-model="memberPersonal2Details.category"
                                    name="mergeDelete.category1">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in categories" :key="'pai'+k" :value="item.id">{{ item.name }}</option>
                            </select><span class="text-sm text-danger">{{ errors.category}}</span>
                          </td>
                        </tr>
                        
                        <tr v-if="subdomain == 'shooting'">
                          <td>ISSF License Certification Number (If applicable) <sup class="text-danger" v-if="fields.demographic.issf.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.issf" v-model="memberPersonal1Details.issf"></td>
                          <td><a :key="issf_key_true" href="#" @click.prevent="dataCopy('issf')" v-if="copy.issf"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="issf_key_false" v-else href="#"  @click.prevent="oldData('issf')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.issf1" v-model="memberPersonal2Details.issf"></td>
                        </tr>

                        <tr v-if="subdomain == 'shooting'">
                          <td>CMP Membership # (If applicable) <sup class="text-danger" v-if="fields.demographic.cmp.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.cmp_no" v-model="memberPersonal1Details.cmp_no"></td>
                          <td><a :key="cmp_no_key_true" href="#" @click.prevent="dataCopy('cmp_no')" v-if="copy.cmp_no"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="cmp_no_key_false" v-else href="#"  @click.prevent="oldData('cmp_no')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.cmp_no1" v-model="memberPersonal2Details.cmp_no"></td>
                        </tr>

                        <tr v-if="subdomain == 'shooting'">
                          <td>NRA Membership # (If applicable) <sup class="text-danger" v-if="fields.demographic.nra.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.nra_no" v-model="memberPersonal1Details.nra_no"></td>
                          <td><a :key="nra_no_key_true" href="#" @click.prevent="dataCopy('nra_no')" v-if="copy.nra_no"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="nra_no_key_false" v-else href="#"  @click.prevent="oldData('nra_no')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.nra_no1" v-model="memberPersonal2Details.nra_no"></td>
                        </tr>

                        <tr v-if="subdomain == 'shooting'">
                          <td>SASP Membership # (If applicable) <sup class="text-danger" v-if="fields.demographic.sasp.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.sasp_no" v-model="memberPersonal1Details.sasp_no"></td>
                          <td><a :key="sasp_no_key_true" href="#" @click.prevent="dataCopy('sasp_no')" v-if="copy.sasp_no"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="sasp_no_key_false" v-else href="#"  @click.prevent="oldData('sasp_no')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.sasp_no1" v-model="memberPersonal2Details.sasp_no"></td>
                        </tr>
                        
                        <tr v-if="subdomain == 'shooting'">
                          <td>SCTP Membership # (If applicable) <sup class="text-danger" v-if="fields.demographic.sctp.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.sctp_no" v-model="memberPersonal1Details.sctp_no"></td>
                          <td><a :key="sctp_no_key_true" href="#" @click.prevent="dataCopy('sctp_no')" v-if="copy.sctp_no"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="sctp_no_key_false" v-else href="#"  @click.prevent="oldData('sctp_no')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.sctp_no1" v-model="memberPersonal2Details.sctp_no"></td>
                        </tr>

                        <tr v-if="subdomain == 'shooting'">
                          <td>How did you hear about USA Shooting? <sup class="text-danger" v-if="fields.demographic.hear_about.required">*</sup></td>
                          <td>
                            <select class="form-control" id="hear_about" :disabled="1" v-model="memberPersonal1Details.hear_about"
                                    name="mergeDelete.hear_about">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in fields.demographic.hear_about.options" :key="'pai'+k" :value="item">{{ item }}</option>
                            </select>
                            </td>
                          <td><a :key="hear_about_key_true" href="#" @click.prevent="dataCopy('hear_about')" v-if="copy.hear_about"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="hear_about_key_false" v-else href="#"  @click.prevent="oldData('hear_about')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                           <select class="form-control form-control2" id="hear_about1"  v-model="memberPersonal2Details.hear_about"
                                    name="mergeDelete.hear_about1">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in fields.demographic.hear_about.options" :key="'pai'+k" :value="item">{{ item }}</option>
                            </select><span class="text-sm text-danger">{{ errors.hear_about}}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>DOB <sup class="text-danger" v-if="fields.personal.birthday.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.dob" v-model="memberPersonal1Details.dob"></td>
                          <td><a href="#" @click.prevent="dataCopy('dob')" v-if="copy.dob"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('dob')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><div class="input-group birthdate">
                            <div class="birthdaypicker"> <date-pick
                          v-model="memberPersonal2Details.dob"
                          :pickTime="false"
                          name="form.dob"
                          :format="'MM/DD/YYYY'"  
                          :selectableYearRange="{from: 1900, to: 2021}"
                          v-mask="'##/##/####'"
                          :isDateDisabled="isFutureDate"
                          placeholder="MM/DD/YYYY"
                          :parseDate="parseDatePick"
                      ></date-pick></div></div><span class="text-sm text-danger">{{ errors.dob}}</span></td>
                        </tr>
                        <tr>
                          <td>Email <sup class="text-danger" v-if="fields.contact.email.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.email" v-model="memberContact1Details.email"></td>
                          <td><a href="#" @click.prevent="dataCopy('email')" v-if="copy.email"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('email')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.email1" v-model="memberContact2Details.email"><span class="text-sm text-danger">{{ errors.email}}</span></td>
                        </tr>
                        <tr v-if="subdomain == 'shooting'">
                          <td>Address Type <sup class="text-danger" v-if="fields.contact.address_type.required">*</sup></td>
                          <td>
                            <select class="form-control" id="address_type" :disabled="1" v-model="memberContact1Details.address_type"
                                    name="mergeDelete.address_type">
                                    <option value="Home">Home Address</option>
                                    <option value="Work">Work Address</option>
                            </select>
                            </td>
                          <td><a :key="address_type_key_true" href="#" @click.prevent="dataCopy('address_type')" v-if="copy.address_type"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="address_type_key_false" v-else href="#"  @click.prevent="oldData('address_type')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                           <select class="form-control form-control2" id="address_type1"  v-model="memberContact2Details.address_type"
                                    name="mergeDelete.address_type1">
                                    <option value="Home" Selected>Home Address</option>
                                    <option value="Work">Work Address</option>
                            </select><span class="text-sm text-danger">{{ errors.address_type}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td v-if="subdomain == 'waterski'">Mailing Address <sup class="text-danger" v-if="fields.contact.address1.required">*</sup></td>
                          <td v-if="subdomain == 'shooting'">Address Line 1 <sup class="text-danger" v-if="fields.contact.address1.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.address" v-model="memberContact1Details.address1"></td>
                          <td><a href="#" @click.prevent="dataCopy('address1')" v-if="copy.address1"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('address1')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.address1" v-model="memberContact2Details.address1"><span class="text-sm text-danger">{{ errors.address1}}</span></td>
                        </tr>
                        <tr>
                          <td v-if="subdomain == 'waterski'">Address 2 <sup class="text-danger" v-if="fields.contact.address2.required">*</sup></td>
                          <td v-if="subdomain == 'shooting'">Address Line 2 <sup class="text-danger" v-if="fields.contact.address2.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.address2" v-model="memberContact1Details.address2"></td>
                          <td><a href="#" @click.prevent="dataCopy('address2')" v-if="copy.address2"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('address2')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.address21" v-model="memberContact2Details.address2"></td>
                        </tr>
                        <tr>
                          <td>Country <sup class="text-danger" v-if="fields.contact.country.required">*</sup></td>
                          <td>
                            <select class="form-control" id="country" :disabled="1" v-model="memberContact1Details.country"
                                    name="paymentForm.billing_country">
                              <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                                      :value="listOfCountry.country"
                                      :selected="memberContact1Details.country == listOfCountry.country">
                                {{ listOfCountry.country }}
                              </option>
                            </select>
                            </td>
                          <td><a href="#" @click.prevent="dataCopy('country')" v-if="copy.country"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('country')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                           <select class="form-control form-control2" @change="clearstateval()" id="country1"  v-model="memberContact2Details.country"
                                    name="paymentForm.billing_country">
                              <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                                      :value="listOfCountry.country"
                                      :selected="memberContact2Details.country == listOfCountry.country">
                                {{ listOfCountry.country }}
                              </option>
                            </select><span class="text-sm text-danger">{{ errors.country}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>City <sup class="text-danger" v-if="fields.contact.city.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.City" v-model="memberContact1Details.city"></td>
                          <td><a href="#" @click.prevent="dataCopy('city')" v-if="copy.city"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('city')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.city1" v-model="memberContact2Details.city"><span class="text-sm text-danger">{{ errors.city}}</span></td>
                        </tr>
                        <tr>
                          <td>State <sup class="text-danger" v-if="fields.contact.state_code.required">*</sup></td>
                          <td>
                            <select class="form-control" v-if="memberContact1Details.country=='USA'" id="state" name="memberContact1Details.state_code"
                                    v-model="memberContact1Details.state_code" :disabled="1">
                              <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                      :value="listOfState.state_code"
                                      :selected="memberContact1Details.state_code == listOfState.state_code">
                                {{ listOfState.state }}
                              </option>
                            </select>
                            <select class="form-control" v-else-if="memberContact1Details.country=='Canada'" id="state" name="memberContact1Details.state_code"
                                    v-model="memberContact1Details.state_code" :disabled="1">
                              <option v-for="listOfState in listOfCAStates" :key="listOfState.state_code"
                                      :value="listOfState.state_code"
                                      :selected="memberContact1Details.state_code == listOfState.state_code">
                                {{ listOfState.state }}
                              </option>
                            </select>
                            <input type="text" class="form-control" readonly v-else name="mergeDelete.state" v-model="memberContact1Details.state">
                            </td>
                          <td><a href="#" @click.prevent="dataCopy('state_code')" v-if="copy.state_code"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('state_code')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                            <select class="form-control form-control2" v-if="memberContact2Details.country=='USA'" id="state" name="memberContact2Details.state_code"
                                    v-model="memberContact2Details.state_code">
                              <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                      :value="listOfState.state_code"
                                      :selected="memberContact2Details.state_code == listOfState.state_code">
                                {{ listOfState.state }}
                              </option>
                            </select>
                            <select class="form-control" v-else-if="memberContact2Details.country=='Canada'" id="castate" name="memberContact2Details.state_code"
                                    v-model="memberContact2Details.state_code" >
                              <option v-for="listOfState in listOfCAStates" :key="listOfState.state_code"
                                      :value="listOfState.state_code"
                                      :selected="memberContact2Details.state_code == listOfState.state_code">
                                {{ listOfState.state }}
                              </option>
                            </select>
                            <input type="text" class="form-control form-control2" v-else name="mergeDelete.state1" v-model="memberContact2Details.state"><span class="text-sm text-danger">{{ errors.state}}</span></td>
                        </tr>
                        <tr>
                          <td>Postal code <sup class="text-danger" v-if="fields.contact.zip.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.zip" v-model="memberContact1Details.zip"></td>
                          <td><a href="#" @click.prevent="dataCopy('zip')" v-if="copy.zip"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('zip')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.zip1" v-model="memberContact2Details.zip"><span class="text-sm text-danger">{{ errors.zip}}</span></td>
                        </tr>

                        <tr v-if="subdomain == 'shooting' && (memberContact1Details.address_type == 'Work' || memberContact2Details.address_type == 'Work')">
                          <td>Company Name <sup class="text-danger" v-if="fields.contact.work_address_company_name.required">*</sup></td>
                          <td><input type="text" readonly class="form-control" name="mergeDelete.company_name" v-model="memberContact1Details.company_name"></td>
                          <td><a href="#" :key="company_name_key_true" @click.prevent="dataCopy('company_name')" v-if="copy.company_name"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#" :key="company_name_key_false"  @click.prevent="oldData('company_name')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><input type="text" class="form-control form-control2" name="mergeDelete.company_name1" v-model="memberContact2Details.company_name"><span class="text-sm text-danger">{{ errors.company_name}}</span></td>
                        </tr>

                        <tr v-if="subdomain == 'shooting' && (memberContact1Details.address_type == 'Work' || memberContact2Details.address_type == 'Work')">
                          <td>Company Phone with Extension <sup class="text-danger" v-if="fields.contact.work_address_company_phone_extension.required">*</sup></td>
                          <td>
                            <div class="row">
                              <div class="col-md-9">
                                <vue-tel-input :disabled="1"
                                             id="memberContact1Details_company_phone"
                                             v-model="memberContact1Details.company_phone"
                                             ref="phone1" maxlength="20"
                                             v-bind="mobileProps"
                                             :validCharactersOnly="true"></vue-tel-input>
                              </div>
                              <div class="col-md-3">
                                <input type="text" readonly class="form-control" name="mergeDelete.company_phone_extension" placeholder="Extension" v-model="memberContact1Details.company_phone_extension">
                              </div>
                            </div>
                           </td>
                          <td><a :key="company_phone_key_true" href="#" @click.prevent="dataCopy('company_phone')" v-if="copy.company_phone"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a :key="company_phone_key_false" v-else href="#"  @click.prevent="oldData('company_phone')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td>
                            <div class="row">
                              <div class="col-md-9">
                                <vue-tel-input
                                  class="col-md-12"
                                  id="memberContact2Details_company_phone"
                                  v-model="memberContact2Details.company_phone"
                                  ref="phone1" maxlength="20"
                                  v-bind="mobileProps"
                                  :key="company_phone_key"
                                  :validCharactersOnly="true"></vue-tel-input><span class="text-sm text-danger">{{ errors.company_phone}}</span>
                              </div>
                              <div class="col-md-3">
                                <input type="text" class="form-control" name="mergeDelete.company_phone_extension1" placeholder="Extension" v-model="memberContact2Details.company_phone_extension">
                              </div>
                            </div>
                            </td>
                        </tr>

                        <tr>
                          <td>Country Code with Home phone <sup class="text-danger" v-if="fields.contact.homephone.required">*</sup></td>
                          <td><vue-tel-input :disabled="1"
                                             id="memberContact1Details_phone_1"
                                             v-model="memberContact1Details.phone_1"
                                             ref="phone1" maxlength="20"
                                             v-bind="mobileProps"
                                             :validCharactersOnly="true"></vue-tel-input></td>
                          <td><a href="#" @click.prevent="dataCopy('phone_1')" v-if="copy.phone_1"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('phone_1')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><vue-tel-input
                              class="col-md-12"
                              id="memberContact2Details_phone_1"
                              v-model="memberContact2Details.phone_1"
                              ref="phone1" maxlength="20"
                              v-bind="mobileProps"
                              :validCharactersOnly="true"></vue-tel-input><span class="text-sm text-danger">{{ errors.phone_1}}</span></td>
                        </tr>
                        <tr>
                          <td>Country Code with Mobile <sup class="text-danger" v-if="fields.contact.mobilephone.required">*</sup></td>
                          <td><vue-tel-input :disabled="1"
                                             id="memberContact1Details_phone_2"
                                             v-model="memberContact1Details.phone_2"
                                             ref="phone1" maxlength="20"
                                             v-bind="mobileProps"
                                             :validCharactersOnly="true"></vue-tel-input></td>
                          <td><a href="#" @click.prevent="dataCopy('phone_2')" v-if="copy.phone_2"><i  class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"  @click.prevent="oldData('phone_2')"><i class="fas fa-undo icon-design"></i></a></td>
                          <td><vue-tel-input
                              class="col-md-12"
                              id="memberContact2Details_phone_2"
                              v-model="memberContact2Details.phone_2"
                              ref="phone1" maxlength="20"
                              v-bind="mobileProps"
                              :validCharactersOnly="true"></vue-tel-input></td>
                        </tr>
                     
                        </tbody>
                      </table>
                      <div class="text-center">
                      <button type="button"  @click="mergeAndDelete()"  class="btn btn-secondary btnnextstp btndisabled btn-next-success">Update</button>
                      </div>
                    </div>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
        <div >
        <b-modal ref="verify-modal" size="lg" hide-footer>
          <div class="d-block">
            <h4>Verify All Data</h4>
            <table class="table table-lg table-bordered">
              <th>Field</th>
              <th>Delete</th>
              <th>Keep</th>
              <tr>
                <td>Member id</td>
                <td>{{form.member_id1}}</td>
                <td>{{form.member_id2}}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>Title</td>
                <td>{{ memberPersonal1Details.title }}</td>
                <td>{{ memberPersonal2Details.title }}</td>
            </tr>
              <tr>
              <td>First Name</td>
              <td>{{ memberPersonal1Details.first_name }}</td>
                <td>{{ memberPersonal2Details.first_name }}</td>
            </tr>
              <tr>
                <td>Middle Initial</td>
                <td>{{ memberPersonal1Details.middle_name }}</td>
                <td>{{ memberPersonal2Details.middle_name }}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{{ memberPersonal1Details.last_name }}</td>
                <td>{{ memberPersonal2Details.last_name }}</td>
              </tr>
              <tr>
                <td>Club/Team</td>
                <td><Select2 :disabled="1" style="width:250px;" v-model="form.teamRoster1" :options="clubOptions" :settings="{ multiple: true }"/></td>
                <td><Select2 :disabled="1" style="width:250px;" v-model="form.teamRoster2"  :options="clubOptions" :settings="{ multiple: true }"/></td>
              </tr>
              <tr v-if="subdomain == 'waterski'">
                <td>Suffix</td>
                <td>{{ memberPersonal1Details.suffix }}</td>
                <td>{{ memberPersonal2Details.suffix }}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{{ memberPersonal1Details.gender }}</td>
                <td>{{ memberPersonal2Details.gender }}</td>
              </tr>
              <tr v-if="subdomain == 'waterski'">
                <td>Federation</td>
                <td>{{ memberPersonal1Details.home_federation }}</td>
                <td>{{ memberPersonal2Details.home_federation }}</td>
              </tr>
              <tr v-if="subdomain == 'waterski'">
                <td>1st Choice</td>
                <td><div v-for="(discipline, index) in disciplines" :key="index"><div v-if="index==memberPersonal1Details.first_choice">{{ discipline}} </div>  </div></td>
                <td><div v-for="(discipline, index) in disciplines" :key="index"><div v-if="index==memberPersonal2Details.first_choice">{{ discipline}} </div>  </div></td>
              </tr>
              <tr v-if="subdomain == 'waterski'">
                <td>2nd Choice</td>
                <td><div v-for="(discipline, index) in disciplines" :key="index"><div v-if="index==memberPersonal1Details.second_choice">{{ discipline}}</div>  </div></td>
                <td><div v-for="(discipline, index) in disciplines" :key="index"><div v-if="index==memberPersonal2Details.second_choice">{{ discipline}}</div>  </div></td>
              </tr>
              <tr>
                <td>Veteran Status</td>
                <td>{{ memberPersonal1Details.veteran_status }}</td>
                <td>{{ memberPersonal2Details.veteran_status }}</td>
              </tr>
              <tr v-if="subdomain == 'waterski'">
                <td>Race</td>
                <td> <div v-for="(name, id) in diversities" :key="id"><div v-if="name.id==memberPersonal1Details.diversity_id">{{ name.name }}</div>  </div></td>
                <td><div v-for="(name, id) in diversities" :key="id"><div v-if="name.id==memberPersonal2Details.diversity_id">{{ name.name }}</div>  </div></td>
              </tr>
              <tr v-if="subdomain == 'waterski'">
                <td>Disability Status</td>
                <td>{{ memberPersonal1Details.disability }}</td>
                <td>{{ memberPersonal2Details.disability }}</td>
              </tr>
              <tr v-if="subdomain == 'waterski'">
                <td>Ethnicity</td>
                <td>{{ memberPersonal1Details.ethnicity }}</td>
                <td>{{ memberPersonal2Details.ethnicity }}</td>
              </tr>
              <tr  v-if="subdomain == 'shooting'">
                <td>Ethnicity</td>
                <td> <div v-for="(name, id) in diversities" :key="id"><div v-if="name.id==memberPersonal1Details.diversity_id">{{ name.name }}</div>  </div></td>
                <td><div v-for="(name, id) in diversities" :key="id"><div v-if="name.id==memberPersonal2Details.diversity_id">{{ name.name }}</div>  </div></td>
              </tr>
              <tr>
                <td>DOB</td>
                <td>{{ memberPersonal1Details.dob }}</td>
                <td>{{ memberPersonal2Details.dob }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{{ memberContact1Details.email }}</td>
                <td>{{ memberContact2Details.email }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>Disciplines of Interest</td>
                <td><Select2 :disabled="1" style="width:250px;" v-model="form.memberDiscipline1" name="form.disciplines3" id="disciplines2" :options="disciplines_list" :settings="{ multiple: true}" /></td>
                <td><Select2 :disabled="1" style="width:250px;" v-model="form.memberDiscipline2" name="form.disciplines4" id="disciplines3" :options="disciplines_list" :settings="{ multiple: true }" /></td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>Primary Role of Interest</td>
                <td>{{ memberPersonal1Details.primary_area_interest }}</td>
                <td>{{ memberPersonal2Details.primary_area_interest }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>Category</td>
                <td>{{ (memberPersonal1Details.category > -1 && categories[memberPersonal1Details.category - 1]) ? categories[memberPersonal1Details.category - 1].name : '' }}</td>
                <td>{{ (memberPersonal1Details.category > -1 && categories[memberPersonal2Details.category - 1]) ? categories[memberPersonal2Details.category - 1].name : '' }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>ISSF License Certification Number (If applicable)</td>
                <td>{{ memberPersonal1Details.issf }}</td>
                <td>{{ memberPersonal2Details.issf }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>CMP Membership # (If applicable)</td>
                <td>{{ memberPersonal1Details.cmp_no }}</td>
                <td>{{ memberPersonal2Details.cmp_no }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>NRA Membership # (If applicable)</td>
                <td>{{ memberPersonal1Details.nra_no }}</td>
                <td>{{ memberPersonal2Details.nra_no }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>SASP Membership # (If applicable)</td>
                <td>{{ memberPersonal1Details.sasp_no }}</td>
                <td>{{ memberPersonal2Details.sasp_no }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>SCTP Membership # (If applicable)</td>
                <td>{{ memberPersonal1Details.sctp_no }}</td>
                <td>{{ memberPersonal2Details.sctp_no }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>How did you hear about USA Shooting?</td>
                <td>{{ memberPersonal1Details.hear_about }}</td>
                <td>{{ memberPersonal2Details.hear_about }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'">
                <td>Address Type</td>
                <td>{{ memberContact1Details.address_type }}</td>
                <td>{{ memberContact2Details.address_type }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'&&memberContact1Details.address_type=='Work'">
                <td>Company Name</td>
                <td>{{ memberContact1Details.company_name }}</td>
                <td>{{ memberContact2Details.company_name }}</td>
              </tr>
              <tr v-if="subdomain == 'shooting'&&memberContact1Details.address_type=='Work'">
                <td>Company Phone with Extension</td>
                <td>{{ memberContact1Details.company_phone+" "+memberContact1Details.company_phone_extension }}</td>
                <td>{{ memberContact2Details.company_phone+" "+memberContact2Details.company_phone_extension }}</td>
              </tr>
              <tr>
                <td  v-if="subdomain == 'waterski'">Mailing Address</td>
                <td  v-if="subdomain == 'shooting'">Address Line 1</td>
                <td>{{ memberContact1Details.address1 }}</td>
                <td>{{ memberContact2Details.address1 }}</td>
              </tr>
              <tr>
                <td v-if="subdomain == 'waterski'">Address 2</td>
                <td v-if="subdomain == 'shooting'">Address Line 2</td>
                <td>{{ memberContact1Details.address2 }}</td>
                <td>{{ memberContact2Details.address2 }}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{{ memberContact1Details.country }}</td>
                <td>{{ memberContact2Details.country }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ (memberContact1Details.country=='USA'||memberContact1Details.country=='Canada')?memberContact1Details.state_code:memberContact1Details.state }}</td>
                <td>{{ (memberContact2Details.country=='USA'||memberContact2Details.country=='Canada')?memberContact2Details.state_code:memberContact2Details.state }}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{{ memberContact1Details.city }}</td>
                <td>{{ memberContact2Details.city }}</td>
              </tr>
              <tr>
                <td>Postal code</td>
                <td>{{ memberContact1Details.zip }}</td>
                <td>{{ memberContact2Details.zip }}</td>
              </tr>
              <tr>
                <td>Country Code with Home phone</td>
                <td>{{ memberContact1Details.phone_1 }}</td>
                <td>{{ memberContact2Details.phone_1 }}</td>
              </tr>
              <tr>
                <td>Country Code with Mobile</td>
                <td>{{ memberContact1Details.phone_2 }}</td>
                <td>{{ memberContact2Details.phone_2 }}</td>
              </tr>
            </table>
            <div>
            <label class="text-left">Note :<sup class="text-danger">*</sup></label>
            <textarea name="note" v-model="form.note" placeholder="Enter note" class="form-control form-control2"></textarea>
              <span class="text-danger">{{ errors.note}}</span>
            </div>
          </div>
          <div class="row">
          <b-button class="mt-3" variant="outline-danger" block @click="toggleModal">Ok</b-button>
          <b-button class="mt-2" variant="outline-warning" block @click="hideModal">Cancel</b-button></div>
        </b-modal>
        </div>
      </section>
</template>
<link href="select2.min.css" rel="stylesheet"/>
<script src="jquery-3.2.1.min.js"></script>
<script src="select2.min.js"></script>
<div id="app"></div>

<script type="text/javascript">
import moment from 'moment';
import axios from "axios";
import DatePick from "vue-date-pick";
import 'vue-date-pick/dist/vueDatePick.css';
import $ from 'jquery';
import CustomAlert from "../CustomAlert";
import select2ajax from '../Select2Ajax';

const validateField = (value,field) => {
  if (value=='' || value==null) {
    return { valid: false, error: field+" is required" };
  }
  return { valid: true, error: null };
};
const validateZip = (zipCode, fieldName = '', country) => {
  let zipText= (fieldName != '' ? fieldName : "Zip code");
  if( zipCode == undefined || !zipCode.length || zipCode.trim() == "")
  {
    return { valid: false, error: zipText+" is required" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
    return { valid: false, error: zipText+" Should Be 5 Number Values for USA." };
  }
  return { valid: true, error: null };
};
const raceValidateField = (value,field) => {
  if (value=='' || value==null || value == 99) {
    return { valid: false, error: field+" is required" };
  }
  return { valid: true, error: null };
};

const dobValidateField = (value,field,keep_member_type_id,subdomain,keep_member_ship_id) => {
if(subdomain == "shooting")
{
  if (value=='' || value==null)
  {
    return { valid: false, error: field+" is required" };
  }
  else if(keep_member_ship_id == 2) {
      let currentDate = new Date();
      let birthDate = new Date(moment(String(value)).format('YYYY/MM/DD'));
      let difference = currentDate - birthDate;
      let age = Math.floor(difference/31557600000);
      if(age < 21)
      {
        return { valid: false, error: field+" Adult Annual user age should be age 21 or older." };
      }
     else
     {
       return { valid: true, error: null };
     }
  }
  else
     {
       return { valid: true, error: null };
     }
}
else
{
  if (value=='' || value==null)
  {
    return { valid: false, error: field+" is required" };
  }
  else if(keep_member_type_id== 1 && keep_member_ship_id == 1) {
      let currentDate = new Date();
      let birthDate = new Date(moment(String(value)).format('YYYY/MM/DD'));
      let difference = currentDate - birthDate;
      let age = Math.floor(difference/31557600000);
      if(age < 25)
      {
        return { valid: false, error: field+" Individual Active user age should be age 25 or older." };
      }
     else
     {
       return { valid: true, error: null };
     }
  }
  else if(keep_member_type_id== 3)
  {
      let currentDate = new Date();
      let birthDate = new Date(moment(String(value)).format('YYYY/MM/DD'));
      let difference = currentDate - birthDate;
      let age = Math.floor(difference/31557600000);
      if(age < 18)
      {
        return { valid: false, error: field+" Family Head of Household must be age 18 or above." };
      }
     else
     {
       return { valid: true, error: null };
     }
  }
  else{   
    return { valid: true, error: null };
  }
}
  
};
export default {
  name: "mergeDelete",
  components: {DatePick,CustomAlert,select2ajax},
  data() {
    return {
      memberOption : [],
      categories : [],
      title_key_true:1,
      title_key_false:1,
      address_type_key_true:1,
      address_type_key_false:1,
      company_name_key_true:1,
      company_name_key_false:1,
      company_phone_key_true:1,
      company_phone_key_false:1,
      company_phone_key:1,
      discipline_interest_key_true:1,
      discipline_interest_key_false:1,
      primary_interest_key_true:1,
      primary_interest_key_false:1,
      category_key_true:1,
      category_key_false:1,
      issf_key_true:1,
      issf_key_false:1,
      cmp_no_key_true:1,
      cmp_no_key_false:1,
      nra_no_key_true:1,
      nra_no_key_false:1,
      sasp_no_key_true:1,
      sasp_no_key_false:1,
      sctp_no_key_true:1,
      sctp_no_key_false:1,
      hear_about_key_true:1,
      hear_about_key_false:1,
      memberPersonal2Details: {
        first_name:'',
        middle_name:'',
        last_name:'',
        suffix:'',
        dob:'',
        veteran_status:'',
        disability:'',
        diversity_id:'',
        ethnicity:'',
        home_federation:'',
        skier_license:'',
        first_choice:'',
        second_choice:'',
        gender:'',
      },
      memberPersonal1Details:{
        first_name:'',
        middle_name:'',
        last_name:'',
        suffix:'',
        dob:'',
        veteran_status:'',
        disability:'',
        diversity_id:'',
        ethnicity:'',
        home_federation:'',
        skier_license:'',
        first_choice:'',
        second_choice:'',
        gender:'',
      },
      memberPersonalDetailsOld:{
        title:'',
        first_name:'',
        middle_name:'',
        last_name:'',
        suffix:'',
        dob:'',
        veteran_status:'',
        disability:'',
        diversity_id:'',
        ethnicity:'',
        home_federation:'',
        skier_license:'',
        first_choice:'',
        second_choice:'',
        gender:'',
        primary_area_interest:'',
        category:'',
        issf:'',
        cmp_no:'',
        nra_no:'',
        sasp_no:'',
        sctp_no:'',
        hear_about:'',
      },
      memberContact1Details:{
        email:'',
        country:'',
        city:'',
        state:'',
        state_code:'',
        address_type:'',
        address1:'',
        address2:'',
        zip:'',
        phone_1:'',
        phone_2:'',
      },
      memberContact2Details:{
        email:'',
        country:'',
        city:'',
        state:'',
        state_code:'',
        address1:'',
        address2:'',
        zip:'',
        phone_1:'',
        phone_2:'',
      },
      memberContactDetailsOld:{
        email:'',
        country:'',
        city:'',
        state:'',
        state_code:'',
        address1:'',
        address2:'',
        zip:'',
        phone_1:'',
        phone_2:'',
      },
      memberErrorMsg:'',
      form:{
        member_id1:'',
        member_id2:'',
        teamRoster1:[],
        teamRoster2:[],
        oldTeamRoster:[],
        memberDiscipline1:[],
        memberDiscipline2:[],
        oldMemberDiscipline:[],
        note:'',
      },
      errors:{
        firstName:'',
        middleName:'',
        lastName:'',
        suffix:'',
        dob:'',
        email:'',
        country:'',
        city:'',
        state:'',
        state_code:'',
        address1:'',
        zip:'',
        member_id1:'',
        member_id2:'',
        phone_1:'',
        phone_2:'',
        veteranStatus:'',
        disability:'',
        race:'',
        ethnicity:'',
        federation:'',
        skier_license:'',
        firstChoice:'',
        secondChoice:'',
        gender:'',
        club:'',
        teamRoster:'',
        primary_area_interest:'',
        address_type:'',
        note:''
      },
      currentDate: moment().format('M/D/YYYY H:mm'),
      copy:{
      title:true,  
      first_name:true,
      last_name:true,
      dob:true,
      email:true,
      address1:true,
      address2:true,
      country:true,
      city:true,
      state:true,
      state_code:true,
      zip:true,
      middle_name:true,
      suffix:true,
      veteran_status:true,
      disability:true,
      diversity_id:true,
      ethnicity:true,
      home_federation:true,
      first_choice:true,
      second_choice:true,
      gender:true,
      phone_1:true,
      phone_2:true,
      teamRoster:true,
      },
      fields:null,
      diversities:[],
      disciplines:[],
      disciplines_list:[],
      club:[],
      keep_member_type_id:'',
      keep_member_ship_id:'',
      displayAlert:false,
      selected: '2',
      selectedAjax: '-1',
      options: [{ text: 'Hello' }, { text: 'World' }],
      optionsAjax: [{ id: -1, text: '' }]
    };
  },
  
    methods: {
      
      getmember:function(value) {
        axios.get(this.basePath + 'api/consolidation/getMembers',{headers: this.adminHeaders})
            .then((res) => {
              if (res.data.status == 'success') {
                this.memberOption = res.data.data;
              } else {
                console.log('error');
              }
            })
            .catch((error) => {
              console.log('error');
              return false;
              // error.response.status Check status code
            }).finally(() => {

        });
      },
      clearstateval(){
        if(this.memberContact2Details.country=='USA'||this.memberContact2Details.country=='Canada'){
          this.memberContact2Details.state_code = '';
        }
      },
      member2change(event){

        if(this.form.member_id2 == this.form.member_id1){
          this.memberErrorMsg = 'please select different member1 and member2';
           return false;
        }
        this.showLoader = true;
        this.getClub(event,'member2');
        this.getMemberDisciplines(event,'member2');
        this.memberErrorMsg =null;
        axios.get(this.basePath + 'api/consolidation/getMemberdetails/'+event,{headers: this.adminHeaders})
            .then((res) => {
              if (res.data.status == 'success') {
                this.memberPersonal2Details = res.data.data.personal_details;
                this.keep_member_type_id = res.data.data.member_type_id;
                this.keep_member_ship_id = res.data.data.membership_id;
                this.memberContact2Details = res.data.data.contact_details
                this.showLoader = false;
                return false;
              } else {
                console.log('error');
              }
            })
            .catch((error) => {
              console.log('error');
              return false;
              // error.response.status Check status code
            }).finally(() => {

        });
      },
      member1change(event){
        if(this.form.member_id2 == event){
          this.memberErrorMsg = 'please select different member1 and member2';
           return false;
        }
        this.memberErrorMsg =null;
        this.getClub(event,'member1');
        this.getMemberDisciplines(event,'member1');
        axios.get(this.basePath + 'api/consolidation/getMemberdetails/'+event,{headers: this.adminHeaders})
            .then((res) => {
              if (res.data.status == 'success') {
                this.memberPersonal1Details = res.data.data.personal_details;
                this.memberContact1Details = res.data.data.contact_details;
                console.log(this.memberContact1Details);
                console.log(this.memberPersonal1Details);
                return false;
              } else {
                console.log('error');
              }
            })
            .catch((error) => {
              console.log('error');
              return false;
              // error.response.status Check status code
            }).finally(() => {

        });
      },
      mergeAndDelete(){
        if (this.checkboxValidation()==true) {
          this.$refs['verify-modal'].show()
        }
        else{
          return false;
        }
      },
     checkboxValidation() {
      this.errors = {};
      this.valid = false;

      const validMemberId1 = validateField(this.form.member_id1, "Member");
      this.errors.member_id1  = validMemberId1 .error;

      const validMemberId2 = validateField(this.form.member_id2, "Member");
      this.errors.member_id2  = validMemberId2 .error;

      const validCountry = validateField(this.memberContact2Details.country, "Country");
      this.errors.country  = validCountry .error;

      const validCity = validateField(this.memberContact2Details.city, "City");
      this.errors.city = validCity.error;
       let validState = '';
      if(this.memberContact2Details.country!= undefined&&(this.memberContact2Details.country == 'USA'||this.memberContact2Details.country == 'Canada')){
         validState = validateField(this.memberContact2Details.state_code, "State");
        this.errors.state = validState.error;
      }
      else{
         validState = validateField(this.memberContact2Details.state, "State");
        this.errors.state = validState.error;
      }

      
      const validFirstName = validateField(this.memberPersonal2Details.first_name, "First Name");
      this.errors.firstName = validFirstName.error;


      const validlastName = validateField(this.memberPersonal2Details.last_name, "Last Name");
      this.errors.lastName = validlastName.error;

      const validDob = dobValidateField(this.memberPersonal2Details.dob, "DOB", this.keep_member_type_id,this.subdomain,this.keep_member_ship_id);
      this.errors.dob = validDob.error;

      const validEmail = validateField(this.memberContact2Details.email, "Email");
      this.errors.email = validEmail.error;
    
      let address_text = "Mailing Address";
      if(this.subdomain == 'shooting')address_text = "Address Line 1";
      const validAddress1 = validateField(this.memberContact2Details.address1, address_text);
      this.errors.address1 = validAddress1.error;

      const validzip = validateZip(this.memberContact2Details.zip, "Postal Code",this.memberContact2Details.country);
      this.errors.zip = validzip.error;

       const validveteran = validateField(this.memberPersonal2Details.veteran_status, "Veteran Status");
       this.errors.veteranStatus = validveteran.error;

       let validskierlicense = { valid: true, error: null };
       if(this.memberPersonal2Details.home_federation !='USA' && this.memberPersonal2Details.home_federation !='')
       {
         validskierlicense = validateField(this.memberPersonal2Details.skier_license, "Home Federation EMS Athlete Number");
          this.errors.skier_license = validskierlicense.error;
       }

       const validPhone1 = validateField(this.memberContact2Details.phone_1, "Home Phone");
       this.errors.phone_1 = validPhone1.error;
      if(this.subdomain == "waterski")
      {
        const validfederation = validateField(this.memberPersonal2Details.home_federation, "Federation");
       this.errors.federation = validfederation.error;
       const validfirst = validateField(this.memberPersonal2Details.first_choice, "First Choice");
        this.errors.firstChoice = validfirst.error;
        const validdisablity = validateField(this.memberPersonal2Details.disability, "Disability");
        this.errors.disability = validdisablity.error;
        const validEthnicity = validateField(this.memberPersonal2Details.ethnicity, "Ethnicity");
        this.errors.ethnicity = validEthnicity.error;
        const validrace = raceValidateField(this.memberPersonal2Details.diversity_id, "Race");
       this.errors.race = validrace.error;

        if(validMemberId1.valid && validMemberId2.valid && validFirstName.valid && validlastName.valid && validDob.valid && validEmail.valid && validCity.valid && validState.valid && validAddress1.valid && validCountry.valid && validzip.valid && validveteran.valid && validfederation.valid && validskierlicense.valid && validfirst.valid && validrace.valid && validEthnicity.valid && validPhone1.valid){
          console.log(validskierlicense.valid);
            return true;
          }else{
            return false;
          }
      }
      if(this.subdomain == "shooting")
      {
        const discipline_interest = validateField(this.form.memberDiscipline2, "Disciplines of Interest");
        this.errors.discipline_interest = discipline_interest.error;
        const primary_area_interest = validateField(this.memberPersonal2Details.primary_area_interest, "Primary Role of Interest");
        this.errors.primary_area_interest = primary_area_interest.error;
        const category = validateField(this.memberPersonal2Details.category, "Category");
        this.errors.category = category.error;
        const hear_about = validateField(this.memberPersonal2Details.hear_about, "Hear about USA Shooting");
        this.errors.hear_about = hear_about.error;
        const address_type = validateField(this.memberContact2Details.address_type, "Address Type");
        this.errors.address_type = address_type.error;
        const validDiversity = raceValidateField(this.memberPersonal2Details.diversity_id, "Ethnicity");
        this.errors.diversity_id = validDiversity.error;
        if(validMemberId1.valid && validMemberId2.valid && validFirstName.valid && validlastName.valid && validDob.valid && validEmail.valid && validCity.valid && validState.valid && validAddress1.valid && validCountry.valid && validzip.valid && validveteran.valid && validPhone1.valid  && primary_area_interest.valid && hear_about.valid && category.valid && address_type.valid && validDiversity.valid && discipline_interest.valid){
          return true;
        }else{
          return false;
        }
      }
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    dataCopy(field){
      if(field=='email' || field=='address1' || field=='phone_1' || field=='address2' || field=='phone_2' || field=='zip' || field=='city' || field=='country' || field=='address_type' || field=='company_name'){
        this.memberContactDetailsOld[field] = this.memberContact2Details[field];
        this.memberContact2Details[field] = this.memberContact1Details[field];
      }else if(field=='state_code'){
        this.memberContactDetailsOld[field] = this.memberContact2Details[field];
        this.memberContact2Details[field] = this.memberContact1Details[field];
        this.memberContactDetailsOld['state'] = this.memberContact2Details['state'];
        this.memberContact2Details['state'] = this.memberContact1Details['state'];
      }
      else if(field=='teamRoster') {
        this.form.oldTeamRoster = this.form.teamRoster2;
        this.form.teamRoster2 = this.form.teamRoster1;
      }else if(field=='discipline_interest') {
        this.form.oldMemberDiscipline = this.form.memberDiscipline2;
        this.form.memberDiscipline2 = this.form.memberDiscipline1;
      }
      else if(field=='home_federation'){
        this.memberPersonalDetailsOld[field] = this.memberPersonal2Details[field];
        this.memberPersonal2Details[field] = this.memberPersonal1Details[field];

        this.memberPersonalDetailsOld['skier_license'] = this.memberPersonal2Details['skier_license'];
        this.memberPersonal2Details['skier_license'] = this.memberPersonal1Details['skier_license'];
      }
      else if(field=='company_phone')
      {
        this.memberContactDetailsOld[field] = this.memberContact2Details[field];
        this.memberContact2Details[field] = this.memberContact1Details[field];

        this.memberContactDetailsOld['company_phone_extension'] = this.memberContact2Details['company_phone_extension'];
        this.memberContact2Details['company_phone_extension'] = this.memberContact1Details['company_phone_extension'];
        this.company_phone_key++;
      }
        else{
        this.memberPersonalDetailsOld[field] = this.memberPersonal2Details[field];
        this.memberPersonal2Details[field] = this.memberPersonal1Details[field];
      }
      this.title_key_true++;
      this.address_type_key_true++;
      this.primary_interest_key_true++;
      this.category_key_true++;
      this.issf_key_true++;
      this.cmp_no_key_true++;
      this.nra_no_key_true++;
      this.sasp_no_key_true++;
      this.company_name_key_true++;
      this.company_phone_key_true++;
      this.discipline_interest_key_true++;
      this.sctp_no_key_true++;
      this.hear_about_key_true++;
      this.copy[field]=false;
    },
    oldData(field){
    if(field=='email' || field=='address1' || field=='phone_1' || field=='address2' || field=='phone_2' || field=='zip' || field=='city' || field=='country' || field=='address_type' || field=='company_name'){
      this.memberContact2Details[field] = this.memberContactDetailsOld[field];
    }
    else if(field=='state_code'){
      this.memberContact2Details[field] = this.memberContactDetailsOld[field];
      this.memberContact2Details['state'] = this.memberContactDetailsOld['state'];
    }
    else if(field=='teamRoster') {
      this.form.teamRoster2 = this.form.oldTeamRoster;
    }
    else if(field=='discipline_interest') {
      this.form.memberDiscipline2 = this.form.oldMemberDiscipline;
    }
    else if(field=='home_federation')
    {
      this.memberPersonal2Details['home_federation'] = this.memberPersonalDetailsOld['home_federation'];

      this.memberPersonal2Details['skier_license'] = this.memberPersonalDetailsOld['skier_license'];
    }
    else if(field=='company_phone')
    {
      this.memberContact2Details[field] = this.memberContactDetailsOld[field];

      this.memberContact2Details['company_phone_extension'] = this.memberContactDetailsOld['company_phone_extension'];
      this.company_phone_key++;
    }
    else{
      this.memberPersonal2Details[field] = this.memberPersonalDetailsOld[field];
    }
    this.title_key_false++;
    this.address_type_key_false++;
    this.company_name_key_false++;
    this.company_phone_key_false++;
    this.discipline_interest_key_false++;
    this.primary_interest_key_false++;
    this.category_key_false++;
    this.issf_key_false++;
    this.cmp_no_key_false++;
    this.nra_no_key_false++;
    this.sasp_no_key_false++;
    this.sctp_no_key_false++;
    this.hear_about_key_false++;
    this.copy[field]=true;
    },
    getDiversity()
      {
        axios.get(this.basePath+"api/getDiversity")
            .then(response => {
              this.diversities = response.data.data;
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      getGroup()
      {
        axios.get(this.basePath+'api/getGroup')
            .then(response => {
              this.groups = response.data.data;
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      getDiscipline()
      {
        axios.get(this.basePath+"api/getDisciplines")
            .then(response => {
              this.disciplines = response.data.data;
              let discipline_codes = Object.keys(this.disciplines);
              if(discipline_codes.length && this.disciplines_list.length == 0){
                discipline_codes.forEach(code => {
                  this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
                });
              }
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      getClubs(){
        axios.get(this.basePath + "api/get_teams")
            .then((response) => {
              var clubs = response.data.data;
              this.clubOptions = clubs;
              console.log(this.clubOptions);
            })
            .catch(error=>{
              console.log("error geting clubs list")
            })
      },
      getCategories(){
          axios.get(this.basePath + "api/categories")
          .then(response => {
              this.categories = response.data.data;
          })
          .catch(error => {
              console.log("Error in getting categories: ", JSON.stringify(error));
          });
    },
      getClub(value,member){
        axios.get(this.basePath + 'api/consolidation/getMemberClub/'+value,{headers: this.adminHeaders})
            .then((response) => {
              if(member=='member1') {
                this.form.teamRoster1 = response.data.data;
                console.log('mike123');
                console.log(this.form.teamRoster1);
              }else{
                this.form.teamRoster2 = response.data.data;
                console.log('mike456');
                console.log(this.form.teamRoster2);
              }
            })
            .catch(error=>{
              console.log("error geting clubs list")
            })


      },
      getMemberDisciplines(value,member){
        axios.get(this.basePath + 'api/consolidation/getMemberDisciplines/'+value,{headers: this.adminHeaders})
            .then((response) => {
              if(member=='member1') {
                this.form.memberDiscipline1 = response.data.data;
                console.log("test123");
                console.log(this.form.memberDiscipline1);
              }else{
                this.form.memberDiscipline2 = response.data.data;
                console.log("test456");
                console.log(this.form.memberDiscipline2);
              }
            })
            .catch(error=>{
              console.log("error geting clubs list")
            })


      },
      toggleModal() {
        if (this.checkNote()==true) {
          this.$refs['verify-modal'].hide();
          var MergeData = [this.memberPersonal1Details, this.memberPersonal2Details, this.memberContact1Details, this.memberContact2Details, this.form];
          axios.post(this.basePath + 'api/consolidation/mergeAndDelete', MergeData, {headers: this.adminHeaders})
              .then((res) => {
                if (res.data.status == 'success') {
                  this.alertMessage = "Consolidated successfully";
                  this.displayAlert = true;
                  setTimeout(function () {
                    location.reload();
                  }, 3000);

                } else {
                  this.alertMessage = "Family Hoh Can't be Consolidated";
                  this.displayAlert = true;
                }
              })
              .catch((error) => {
                console.log('Error:' + error);
              }).finally(() => {
           
          });
        } else{
          return false;
        }
      },
      hideModal() {
        this.$refs['verify-modal'].hide();
        return false;
      },
      checkNote(){
        this.errors = {};
        this.valid = false;

        const validNote = validateField(this.form.note, "Note");
        this.errors.note  = validNote .error;
        if(validNote.valid){
          return true;
        }else
        {
          return false;
        }
            }

},
  mounted() {
    this.copy.title = true;
    this.copy.address_type = true;
    this.copy.company_name = true;
    this.copy.company_phone = true;
    this.copy.discipline_interest = true;
    this.copy.primary_area_interest = true;
    this.copy.category = true;
    this.copy.issf = true;
    this.copy.cmp_no = true;
    this.copy.nra_no = true;
    this.copy.sasp_no = true;
    this.copy.sctp_no = true;
    this.copy.hear_about = true;
    this.getmember();
    this.getDiversity();
    this.getGroup();
    this.getDiscipline();
    this.getClubs();
    this.getCategories();
    const retry = setInterval(() => {
      this.fields = this.getSiteProps('memberRegistration.individual.fields');
      if(this.fields) clearInterval(retry);
    }, 2000);
    
  },
      computed: {
      },
      created() {
        $('#select2ajax-template').on('change', function(){
        });
    }

}
</script>

<style >
    .table-area th{
        width: auto !important;
    }
    .icon-design{
        font-size:30px; 
        color:#007bff;
        margin-top:4px
    }

    .select2-selection {
      border-radius: 0px !important;
      text-align: left !important;
    }
    .select2-dropdown {
      border-radius: 0px !important;
    }
    .form-control2{
      background: #fff !important;
    }
    .vdpHeadCell {
    padding: 0.3em 0.5em 1.8em !important;
  }
</style>