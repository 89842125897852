<template>
  <select id="select2ajax-template" style="width: 100%">
  </select>
</template>

<script>
import $ from 'jquery';
export default {
  name: 'select2ajax',
  props: ['options', 'value'],
  template: '#select2ajax-template',
  mounted: function() {
    var vm = this;
    $(this.$el)
        // init select2
        .select2({
          ajax: {
            url: this.basePath + 'api/consolidation/getMembers',
            dataType: 'json',
            headers: this.adminHeaders,
            delay: 500,
            cache: true,
            data: function(params) {
              var query = {
                search: params.term
              };
              // Query parameters will be ?search=[search]&_type=query&q=q
              return query;
            },
            // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
            processResults: function(data) {

              // Tranforms the top-level key of the response object from 'items' to 'results'
              var result_data = $.map(data.data, function(obj) {
                obj.text = obj.name;
                return obj;
              });
              return {
                results: result_data
              };
            }
          },
          placeholder: 'Search Member Name / ID',
          minimumInputLength: 3,
          templateResult: function(repo) {
            if (repo.id) {
              return repo.text;
            }
            return repo.text;
          },
          templateSelection: function(repo) {
            if (repo.id) {
              return repo.text;
            }
            return repo.text;
          }
        })
        .val(this.value)
        .trigger('change')
        // emit event on change.
        .on('change', function() {
          vm.$emit('input', this.value);
        });
  },
  watch: {
    value: function(value) {
      // update value
      $(this.$el).val(value);
    },
    options: function(options) {
      // update options
      $(this.$el)
          .empty()
          .select2({ data: options });
    }
  },
  destroyed: function() {
    $(this.$el)
        .off()
        .select2('destroy');
  }
};
</script>

<style>
</style>
